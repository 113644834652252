import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import "../assets/css/Register.css";
import ToastPopUp from "../components/ui/ToastPopUp";
import axios from "axios";

const Verification = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isLoading, setIsLoading] = useState(false);
  //
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  // images
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [verificationImage, setVerificationImage] = useState<string[]>([]);
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  ////console.log(code);

  useEffect(() => {
    requestUserInfo();
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goLogin() {
    window.location.href = "/login";
  }

  const showPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hidePopup();
  };

  const hidePopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const doUpdate = () => {
    if (!selectedImage) {
      alert("Please select an image before uploading.");
      return;
    }
    setIsLoading(true);
    requestUploadImage();
  };

  /**
   * api
   */
  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  const requestUploadImage = async () => {
    if (selectedImage != null) {
      const formData = new FormData();
      formData.append("uploadFile", selectedImage);
      try {
        const response = await axios.post(hostUrl + "/upload/images", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        ////console.log(response);
        if (response.data.data.images.length > 0) {
          setVerificationImage(response.data.data.images[0]);
        }
        requestUpdate(response.data.data.images[0]);
      } catch (e) {
        setSelectedImage(null);
        setPreviewImage(null);
        setIsLoading(false);
      }
    }
  };

  const requestUpdate = async (verificationImage: string): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        hostUrl + "/auth/updateKyc",
        {
          verificationImage: verificationImage,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        if (response.data.code === 0) {
          showPopup("Successfully updated.");
          setTimeout(function () {
            window.history.back();
          }, 1000);
        } else {
          showPopup(response.data.message);
        }
      } else {
        showPopup("Failed to register.");
      }
      setIsLoading(false);
      //window.location.replace("/login");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="flex flex-row justify-center w-full h-full bg-white">
        <div className="register">
          <h1>KYC Photo Verification</h1>

          <section>
            <div className="flex flex-col justify-start items-center max-w-[600px] mt-5 px-4 py-10 mx-auto b">
              <p className="text-gray-600 mb-8 text-left">
                To complete your identity verification, please upload a photo of a valid ID. Ensure
                the image is clear and the information is readable.
              </p>

              <div className="w-full bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
                <div className="w-full mb-4">
                  <label
                    htmlFor="fileInput"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Select an Image
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/jpeg, image/png"
                    className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:outline-none"
                    onChange={handleImageChange}
                  />
                </div>

                {previewImage && (
                  <div className="mb-4">
                    <p className="text-sm text-gray-600 mb-2">Selected Image:</p>
                    <img
                      src={previewImage}
                      alt="Preview"
                      className="w-32 h-32 object-cover rounded-lg border"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="px-10 mt-10">
              <button
                className="w-full h-[50px] bg-[var(--t-color-point)] text-lg text-white rounded-lg"
                disabled={isLoading}
                onClick={doUpdate}
              >
                Update
              </button>
            </div>
          </section>
        </div>
      </section>
      <div className="flex flex-row justify-center w-full h-auto">
        <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
      </div>
    </div>
  );
};

export default Verification;
