import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../assets/css/Common.css";
import { formatNumber, formatTime } from "../common/Utils";

interface Props {
  changeAmount: (amount: number) => void; // 숫자를 매개변수로 받는 함수 타입
  doLong: () => void;
  doShort: () => void;
  asset: any;
  position: any;
  timeLeft: number;
}

const TradeOption: React.FC<Props> = ({
  changeAmount,
  doLong,
  doShort,
  asset,
  position,
  timeLeft,
}) => {
  const minAmount = 0;
  const maxAmount = 4000;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<string>("");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [profitPercentage, setProfitPercentage] = useState<number>(83);

  const buttons = [
    { label: "10", amount: 10 },
    { label: "50", amount: 50 },
    { label: "100", amount: 100 },
    { label: "200", amount: 200 },
    { label: "500", amount: 500 },
    { label: "1,000", amount: 1000 },
    { label: "RESET", amount: 0 },
  ];

  useEffect(() => {
    const verifyAccessToken = async () => {
      const accessToken = localStorage.getItem("accessToken"); // Retrieve from storage or context
      ////console.log(accessToken);
      if (!accessToken) {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
    };
    verifyAccessToken();
  }, []);

  function goLogin() {
    window.location.href = "/login";
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "");
    const regex = /^[0-9\b]+$/; // Only allows numbers and backspace
    if (regex.test(rawValue) || rawValue === "") {
      setAmountValue(formatNumber(rawValue));
      setAmount(parseInt(rawValue, 10));
    }
  };

  const handleAmountBlur = () => {
    const numericValue = parseInt(amountValue.replace(/,/g, ""), 10);
    if (numericValue < 0) {
      setAmountValue("0");
      changeAmount(0);
    } else if (isNaN(numericValue)) {
      setAmountValue("");
      changeAmount(0);
    } else {
      setAmountValue(formatNumber(numericValue.toString()));
      changeAmount(numericValue);
    }
  };

  const handleAmountButtonClick = (addAmount: number) => {
    if (addAmount === 0) {
      setAmount(0);
      setAmountValue("");
      //
      changeAmount(0);
    } else {
      let newAmount = amount + addAmount;
      setAmount(newAmount);
      setAmountValue(formatNumber(newAmount.toString()));
      //
      changeAmount(newAmount);
    }
  };

  const getAvailOrder = (): string => {
    var availOrder = 0;
    if (asset) {
      const balance = asset?.usdtm;
      const btc = asset?.btc;
      const usdt = asset?.usdt;
      const ura = asset?.usingRequiredAmount;
      const lwra = asset?.longWaitingRequiredAmount;
      const swra = asset?.shortWaitingRequiredAmount;

      var availOrder = 0;
      if (position != null) {
        if (position?.position == 1) {
          availOrder = balance - ura - lwra;
        } else if (position?.position == 2) {
          availOrder = balance - ura - swra;
        }
      } else {
        var minus = 0;
        if (lwra > swra) {
          minus = lwra;
        }
        if (swra > lwra) {
          minus = swra;
        }
        availOrder = balance - minus;
      }
    }

    return availOrder.toString();
  };

  return (
    <div className="w-full h-auto pb-5">
      <div className="mt-3">
        <div className="mt-2">
          <h1 className="text-sm text-white">Amount</h1>
          <div className="relative flex items-center w-full h-[50px] mt-2">
            <input
              type="text"
              className="w-full h-full pl-2 pr-[100px] py-2 border-2 border-gray-400 focus:border-white outline-none p-0.5 bg-transparent text-white rounded-lg"
              placeholder="Amount"
              value={amountValue}
              onChange={handleAmountChange}
              onBlur={handleAmountBlur}
            />
            <span className="absolute right-0 mr-3 text-sm text-white">BSC</span>
          </div>
        </div>
        <div className="flex flex-row flex-wrap items-start justify-start mt-3">
          {buttons.map((button, index) => (
            <button
              key={index}
              className={`w-[30%] ml-2 mt-2 hover:bg-[var(--t-color-point)] text-white border border-white rounded-lg cursor-pointer p-1 transition duration-300 ease-in-out`}
              onClick={() => handleAmountButtonClick(button.amount)}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className="flex justify-between mt-1"></div>
        <div className="mt-4">
          <h2 className="text-lg text-center text-white">Expiration Time</h2>
          <div className="flex flex-row items-center justify-center mt-1">
            <h2 className="font-bold text-xl text-center text-[var(--t-color-point)]">
              {formatTime(timeLeft)}
            </h2>
          </div>
        </div>
        {/*
        <div className="mt-4">
          <h2 className="text-lg text-center text-white">Profit</h2>
          <div className="flex flex-row items-center justify-center mt-1">
            <h2 className="font-bold text-xl text-center text-[var(--t-color-point)]">
              {profitPercentage.toFixed(0)}%
            </h2>
          </div>
        </div>
          */}
        <div className="flex flex-row items-center justify-center mt-5">
          <button
            className="w-[48%] h-full py-3 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-[var(--t-color-green)] text-sm text-[var(--t-color-green)] rounded-xl"
            onClick={doLong}
          >
            Long
          </button>
          <button
            className="w-[48%] h-full ml-3 py-3 bg-[var(--t-color-darkgray)] border border-gray-600 hover:border-[var(--t-color-red)] text-sm text-[var(--t-color-red)] rounded-xl"
            onClick={doShort}
          >
            Short
          </button>
        </div>
        <div className="py-1 mt-5">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Avail. USDT Futures: <span className="text-white">{formatNumber(getAvailOrder())}</span>
          </h3>
        </div>
        <div className="w-full mt-3 border border-gray-700"></div>
        {!isLoggedIn ? (
          <div className="px-1 mt-3">
            <button
              className="w-full h-[40px] bg-[var(--t-color-point)] text-lg text-white rounded-3xl"
              onClick={goLogin}
            >
              Log In
            </button>
          </div>
        ) : null}
        {/*
           <div className="flex flex-row items-center justify-between mt-3">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">0</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Cost: <span className="text-white">0</span>
          </h3>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Max price: <span className="text-white">0</span>
          </h3>
          <h3 className="text-[0.8rem] text-[var(--t-color-gray-400)]">
            Min price: <span className="text-white">0</span>
          </h3>
        </div>
          */}
      </div>
    </div>
  );
};

export default TradeOption;
