import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import CustomSelect from "../components/ui/CustomSelect";
import WithdrawList from "../components/ui/WithdrawList";
import axios from "axios";
import AlertPopUp from "../components/ui/AlertPopUp";
import { formatNumberWithCommas } from "../components/common/Utils";

const cryptos: any[] = [
  { value: "0", label: "Select crypto" },
  { value: "BSC", label: "USDT (BEP-20)" },
];

const networks: any[] = [
  { value: "0", label: "Select network" },
  { value: "USDT (BEP-20)", label: "USDT (BEP-20)" },
];

const Withdraw = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [selectedCrypto, setSelectedCrypto] = useState(cryptos[0].value);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);
  const [walletAddress, setWalletAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [result, setResult] = useState("");
  const [userid, setUserid] = useState("");
  const [asset, setAsset] = useState<any>(null);
  const [withdraws, setWithdraws] = useState([]);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    requestUserBalance();
    requestWithdrawLookup();
  }, []);

  const handleCryptoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCrypto(e.target.value);
    setStepIndex(1);
  };

  const handleNetworkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCrypto(e.target.value);
  };

  const destinationAddressChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setWalletAddress(event.target.value);
  };

  const amountChange = (event: { target: { value: string } }) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, ""); // 숫자만 허용

    if (inputValue === "") {
      setAmount("");
      setResult("");
      return;
    }

    const numericValue = Number(inputValue);
    setAmount(numericValue.toLocaleString()); // 콤마 자동 추가
    setResult(Math.max(numericValue + 5, 0).toLocaleString()); // -5 적용 후 0 이하 방지
  };

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goWithdrawDetail() {
    window.location.href = "/withdraw/history";
  }

  function doWithdrawAll() {
    if (asset?.bsc) {
      const rawAmount = asset.bsc;
      const deductedAmount = Math.max(rawAmount + 5, 0); // Ensure it doesn't go below 0

      setAmount(rawAmount.toLocaleString()); // Display formatted amount
      setResult(deductedAmount.toLocaleString()); // Store deducted amount
    }
  }

  function confirm() {
    if (walletAddress === "") {
      showAlertPopup("Please input destination address.");
      return;
    }
    if (amount === "") {
      showAlertPopup("Please input amount.");
      return;
    }
    requestRegisterWithdraw();
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestUserBalance = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setAsset(response.data.data);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  const requestWithdrawLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/withdraw/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      ////console.log(response);
      if (response.data.code == 0) {
        setWithdraws(response.data.data.withdraws);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  const requestRegisterWithdraw = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/withdraw/register";
    const removeAmoutComma = amount.replace(/,/g, "");
    try {
      const response = await axios.post(
        requestUrl,
        {
          walletAddress,
          amount : removeAmoutComma,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      ////console.log(response);
      if (response.data.code === 0) {
        requestUserBalance();
        requestWithdrawLookup();
        setWalletAddress("");
        setAmount("");
        showAlertPopup("Successfully registered.");
      } else {
        showAlertPopup(response.data.message);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="content-top content-padding">
          <h1 className="font-bold text-xl text-white">Withdraw</h1>
          <div className="mt-5 p-3 border border-[var(--t-color-point)] rounded-xl">
            <h2 className="text-white">Total Amount</h2>
            <h3 className="font-bold text-white">
              USDT (BEP-20) :
              <span className="ml-1">
                {asset?.bsc == null ? "0" : formatNumberWithCommas(asset?.bsc)}
              </span>
            </h3>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <h1 className="w-[35px] h-[30px] bg-[var(--t-color-point)] font-bold text-sm text-white text-center leading-[30px] rounded-full">
              1
            </h1>
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3 className="font-bold text-white text-center">Select crypto to withdraw</h3>
              <div className="control-width h-auto mt-3">
                <CustomSelect
                  options={cryptos}
                  value={selectedCrypto}
                  onChange={handleCryptoChange}
                  isDisable={stepIndex === 0 ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <h1
              className={`w-[35px] h-[30px] font-bold text-sm text-white text-center leading-[30px] rounded-full ${
                stepIndex === 1 ? "bg-[var(--t-color-point)]" : "bg-[var(--t-color-disable)]"
              }`}
            >
              2
            </h1>
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3
                className={`font-bold text-center ${
                  stepIndex === 1 ? "text-[var(--t-color-7)]" : "text-[var(--t-color-disable)]"
                }`}
              >
                Withdraw details
              </h3>
              {stepIndex === 1 && (
                <div className="control-width h-auto mt-3">
                  <div className="">
                    <label
                      htmlFor="first_name"
                      className="block mb-2 text-sm font-medium text-white"
                    >
                      Destination Address
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                      placeholder="Destination Address"
                      value={walletAddress}
                      onChange={destinationAddressChange}
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <div className="flex flex-row justify-between items-end">
                      <label htmlFor="first_name" className="block text-sm font-medium text-white">
                        Amount
                      </label>
                      <button
                        className="w-auto h-auto px-2 py-1 border border-[var(--t-color-point)] focus:bg-[var(--t-color-point)] text-sm text-white rounded-xl cursor-pointer"
                        onClick={doWithdrawAll}
                      >
                        Withdraw All
                      </button>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className="block w-full mt-3 p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                      placeholder="Amount"
                      value={amount}
                      onChange={amountChange}
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <div className="flex flex-row justify-between items-end">
                      <label htmlFor="first_name" className="block text-sm font-medium text-white">
                        Final Amount
                      </label>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className="block w-full mt-3 p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                      placeholder=""
                      value={result}
                      disabled={true}
                    />
                  </div>
                  <div className="mt-2">
                    <h3 className="text-sm text-gray-400">Network Fee: 5 / Minimum amount: 10</h3>
                  </div>
                  <div className="mt-10 flex flex-row justify-start items-start">
                    <button
                      className="w-full px-3 py-2 border border-[var(--t-color-point)] focus:bg-[var(--t-color-point)] text-sm text-white rounded-xl cursor-pointer"
                      onClick={confirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*
            List
            */}
          <section className="mt-10 pb-5">
            <h1 className="text-xl text-white">Recent Withdraws</h1>
            <WithdrawList withdraws={withdraws} />
            <div className="flex flex-row justify-center mt-2">
              <button
                className="flex items-center text-base text-[var(--t-color-point)] hover:underline focus:outline-none"
                onClick={goWithdrawDetail}
              >
                <span>View more</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </section>
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default Withdraw;
