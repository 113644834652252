import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import "../assets/css/Common.css";
import CustomSelect from "../components/ui/CustomSelect";
import WithdrawList from "../components/ui/WithdrawList";
import axios from "axios";
import { formatNumber } from "../components/common/Utils";
import AlertPopUp from "../components/ui/AlertPopUp";
import TransferHistoryList from "../components/ui/TransferHistoryList";

const TransferFutures = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState("USDT (BEP-20)");
  const [selectedTarget, setSelectedTarget] = useState("USDT-M (FUTURES)");
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [amountValue, setAmountValue] = useState("");
  const [amount, setAmount] = useState<number>(0);
  const [userid, setUserid] = useState("");
  const [transfers, setTransfers] = useState([]);
  const [exchangeStatus, setExchangeStatus] = useState(false);
  //
  const [asset, setAsset] = useState<any>(null);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    requestUserBalance();
    requestTransferFuturesLookup();
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function goTransferDetail() {
    window.location.href = "/transfer/history/2";
  }

  const handleFromChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFrom(e.target.value);
  };

  const handleTargetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTarget(e.target.value);
  };

  const amountChange = (event: { target: { value: string } }) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, ""); // 숫자만 허용

    if (inputValue === "") {
      setAmount(0);
      setAmountValue("");
      return;
    }

    const numericValue = Number(inputValue);
    setAmount(numericValue);
    setAmountValue(numericValue.toLocaleString()); // 콤마 자동 추가
  };

  function exchange() {
    if (exchangeStatus) {
      if (asset != null) {
        setMaxAmount(asset?.bsc);
      }
    } else {
      if (asset != null) {
        setMaxAmount(asset?.usdtm);
      }
    }
    setExchangeStatus(!exchangeStatus);
  }

  function max() {
    setAmount(maxAmount);
    setAmountValue(formatNumber(maxAmount.toString()));
  }

  function confirm() {
    if (amount == 0) {
      showAlertPopup("Please enter amout.");
      return;
    }
    var to = "bsc";
    if (exchangeStatus) {
      to = "bsc";
    } else {
      to = "usdtm";
    }
    //console.log(amount);
    //console.log(to);
    requestRegisterTransferFutures(to);
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestUserBalance = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setAsset(response.data.data);
        setMaxAmount(response.data.data.bsc);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  const requestRegisterTransferFutures = async (to: string): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/transfer/registerFutures";
    try {
      const response = await axios.post(
        requestUrl,
        {
          to,
          amount,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      ////console.log(response);
      if (response.data.code === 0) {
        setAmountValue("");
        requestUserBalance();
        requestTransferFuturesLookup();
        showAlertPopup("Successfully registered.");
      } else {
        showAlertPopup(response.data.message);
      }
    } catch (error) {}
  };

  const requestTransferFuturesLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/transfer/lookupForTop?type=2";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      ////console.log(response);
      if (response.data.code == 0) {
        setTransfers(response.data.data.transfers);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-xl text-white">Transfer Futures</h1>
          <div className="flex flex-row justify-start items-start mt-5">
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3 className="font-bold text-white text-center">From</h3>
              <div className="control-width h-auto mt-3">
                <h1 className="p-3 bg-[var(--t-color-point)] font-bold text-lg text-white rounded-xl">
                  {exchangeStatus ? selectedTarget : selectedFrom}
                </h1>
              </div>
            </div>
          </div>
          <div
            className="control-width flex flex-row justify-center items-center mt-10"
            onClick={exchange}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              cursor="pointer"
              width="24"
              height="24"
            >
              <path
                d="M7.5 3h3v18.5l-7-7h4V3zM16.5 21h-3V2.5l7 7h-4V21z"
                fill="var(--t-color-point)"
              ></path>
            </svg>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <h3 className="font-bold text-white text-center">To</h3>
              <div className="control-width h-auto mt-3">
                <h1 className="p-3 bg-[var(--t-color-point)] font-bold text-lg text-white rounded-xl">
                  {exchangeStatus ? selectedFrom : selectedTarget}
                </h1>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start items-start mt-5">
            <div className="flex flex-col justify-start items-start w-full h-auto ml-3 mt-1">
              <div className="control-width h-auto mt-3">
                <div>
                  <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-white">
                    Amount
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="block w-full p-2.5 text-sm text-[var(--t-color-point)] rounded-lg focus:ring-[var(--t-color-point)] focus:border-[var(--t-color-point)] border border-gray-300"
                    placeholder="Amount"
                    value={amountValue}
                    onChange={amountChange}
                    required
                  />
                </div>
                <div className="mt-2 flex flex-row justify-between items-center">
                  <span className="text-sm text-white">
                    Max. Amount: {formatNumber(maxAmount.toString())}
                  </span>
                  <button
                    className="px-3 py-1 border border-[var(--t-color-point)] focus:bg-[var(--t-color-point)] text-sm text-white rounded-xl cursor-pointer"
                    onClick={max}
                  >
                    MAX
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-row justify-start items-start">
            <button
              className="control-width px-3 py-2 border border-[var(--t-color-point)] focus:bg-[var(--t-color-point)] text-sm text-white rounded-xl cursor-pointer"
              onClick={confirm}
            >
              Confirm
            </button>
          </div>
        </div>
        {/*
            List
            */}
        <section className="content-padding mt-10 pb-5">
          <h1 className="text-xl text-white">Recent Transfers</h1>
          <TransferHistoryList transferHistories={transfers} />
          <div className="flex flex-row justify-center mt-2">
            <button
              className="flex items-center text-base text-[var(--t-color-point)] hover:underline focus:outline-none"
              onClick={goTransferDetail}
            >
              <span>View more</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 ml-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </section>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default TransferFutures;
