import { useEffect, useState } from "react";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Header from "../components/ui/Header";
import axios from "axios";
import goImage from "../assets/images/sys_icon_go.svg";

const Profile = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [userInfo, setUserInfo] = useState<any>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [linkToShare, setLinkToShare] = useState<string>("");
  const [kycStatus, setKycStatus] = useState<number>(0);

  useEffect(() => {
    requestUserInfo();
  }, []);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(linkToShare)
      .then(() => {
        alert("The referralLink has been copied to the clipboard!");
      })
      .catch(() => {
        alert("Failed to copy the link.");
      });
  };

  function doLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  function goToKYCPage() {
    window.location.href = "/verification";
  }

  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
        setKycStatus(response.data.data.approveStatus);
        setLinkToShare("http://vibexglobal.com/register/" + response.data.data.parent2ReferralCode);
      } else {
        if (response.data.code === 40000) {
          localStorage.removeItem("accessToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full">
        <div className="w-full max-w-[600px] mx-auto">
          <section className="relative flex flex-col justify-start items-center mt-10 px-5 py-5">
            <div className="w-[50px] h-[50px] mt-5 rounded-full bg-[var(--t-color-point)]"></div>
            <span className="mt-3 text-white text-[1rem] font-bold">{userInfo?.name}</span>
            <span className="text-white text-[0.8rem]">{userInfo?.email}</span>
          </section>

          {/* Link Copy Section */}
          <section className="w-full px-5">
            <h1 className="font-bold text-[0.8rem] text-white">Referral link</h1>
            <div className="flex justify-between items-center">
              <span className="text-white text-[0.8rem] break-all">{linkToShare}</span>
              <button
                onClick={handleCopyLink}
                className="px-4 py-2 bg-[var(--t-color-point)] text-white text-[0.8rem] rounded-lg"
              >
                Copy
              </button>
            </div>
          </section>

          {userInfo && (
            <section className="w-full px-5 mt-4 mb-4">
              <h1 className="font-bold text-[0.8rem] text-white">KYC Status</h1>
              <div className="flex justify-between items-center justify-center">
                <p
                  className={`text-[0.8rem] mt-1 ${
                    kycStatus === 1
                      ? "text-green-400"
                      : kycStatus === 2
                      ? "text-red-400"
                      : "text-yellow-400"
                  }`}
                >
                  {kycStatus === 1 && "You are verified."}
                  {kycStatus === 2 && "Verification required."}
                  {kycStatus === 3 && "Verification pending."}
                </p>

                {kycStatus === 2 && (
                  <button
                    onClick={goToKYCPage}
                    className="px-4 py-2 bg-[var(--t-color-point)] text-white text-[0.8rem] rounded-lg"
                  >
                    Verify
                  </button>
                )}
              </div>
            </section>
          )}

          <section className="w-full px-5">
            <div
              className="flex flex-row justify-between items-center w-full h-[60px]"
              onClick={doLogout}
            >
              <span className="text-red-500 font-bold text-[0.8rem]">Log out</span>
              <img src={goImage} alt="" className="w-[15px] h-[15px]" />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Profile;
