import React, { useEffect, useState } from "react";
import logoImage from "../../assets/images/sys_icon_logo.svg";
import menuImage from "../../assets/images/nav_btn_menu.svg";
import iconImage from "../../assets/images/icon.png";
import profileImage from "../../assets/images/sys_icon_profile.svg";
import notiImage from "../../assets/images/sys_icon_notification.svg";
import defaultUserImage from "../../assets/images/default_user.svg";
import { Desktop, Mobile } from "../../hooks/mediaQuery";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../common/Utils";

const Header = (props: any) => {
  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [userInfo, setUserInfo] = useState<any>(null);
  const [bsc, setBsc] = useState<number>(0);
  const [alarms, setAlarms] = useState<any>([]);
  const [isTradePopUpMenuVisible, setIsTradePopUpMenuVisible] = useState(false);
  const [isWalletPopUpMenuVisible, setIsWalletPopUpMenuVisible] = useState(false);
  const [isSupportPopUpMenuVisible, setIsSupportPopUpMenuVisible] = useState(false);
  const [isAssetPopUpMenuVisible, setIsAssetPopUpMenuVisible] = useState(false);
  const [isNotiPopUpMenuVisible, setIsNotiPopUpMenuVisible] = useState(false);

  const isLogin = localStorage.getItem("accessToken");

  useEffect(() => {
    requestUserInfo();
    requestWallet();
    requestAlarmLookup();
  }, []);

  const tradeMouseOver = () => {
    setIsTradePopUpMenuVisible(true);
  };

  const tradeMouseOut = () => {
    setIsTradePopUpMenuVisible(false);
  };

  const walletMouseOver = () => {
    setIsWalletPopUpMenuVisible(true);
  };

  const walletMouseOut = () => {
    setIsWalletPopUpMenuVisible(false);
  };

  const supportMouseOver = () => {
    setIsSupportPopUpMenuVisible(true);
  };

  const supportMouseOut = () => {
    setIsSupportPopUpMenuVisible(false);
  };

  const assetMouseOver = () => {
    setIsAssetPopUpMenuVisible(true);
  };

  const assetMouseOut = () => {
    setIsAssetPopUpMenuVisible(false);
  };

  const notiMouseOver = () => {
    setIsNotiPopUpMenuVisible(true);
  };

  const notiMouseOut = () => {
    setIsNotiPopUpMenuVisible(false);
  };

  function doLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  /**
   * api
   */
  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setUserInfo(response.data.data);
      }
    } catch (error) {}
  };

  const requestWallet = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      ////console.log(response);
      if (response.data.code == 0) {
        setBsc(response.data.data.bsc);
      }
    } catch (error) {}
  };

  const requestAlarmLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/alarm/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setAlarms(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <header>
      <Desktop>
        <div className="w-full h-[48px] bg-[var(--t-color-gray-800)]">
          <div className="relative flex flex-row w-full h-full px-10">
            <div className="flex flex-row items-center h-full">
              <img src={logoImage} alt="" className="w-[30px] h-[30px]" />
              <Link to="/">
                <h1 className="ml-2 font-extrabold text-[1.2rem] text-white cursor-pointer">
                  Vibex
                </h1>
              </Link>
            </div>
            <div className="flex flex-row items-center justify-between w-full px-10">
              <ul className="flex flex-row items-center h-full">
                <li className="relative h-full">
                  <Link to="/markets">
                    <div className="flex flex-row items-center justify-center h-full">
                      <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                        Markets
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  className="relative h-full"
                  onMouseOver={tradeMouseOver}
                  onMouseOut={tradeMouseOut}
                >
                  <div className="flex flex-row items-center justify-center h-full">
                    <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                      Trade
                    </span>
                  </div>
                  <div
                    className={`${
                      isTradePopUpMenuVisible ? "" : "hidden"
                    } absolute w-auto h-auto ml-[-1rem] px-3 py-5 top-[48px] z-50 bg-white shadow-lg rounded-xl`}
                  >
                    <Link to="/futures">
                      <div className="flex flex-row justify-start items-center w-[300px] h-auto px-5 py-2 hover:bg-gray-100">
                        <div className="flex flex-col items-start justify-center ml-3">
                          <div className="text-[0.9rem] font-extrabold text-black">Futures</div>
                          <div className="text-[0.8rem] text-[var(--t-color-gray-600)]">
                            Futures are financial contracts obligating the buyer to purchase an
                            asset, or the seller to sell an asset, at a predetermined future date
                            and price
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/options">
                      <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                        <div className="flex flex-col items-start justify-center ml-3">
                          <div className="text-[0.9rem] font-extrabold text-black">Option</div>
                          <div className="text-[0.8rem] text-[var(--t-color-gray-600)]">
                            Options trading involves buying and selling contracts that grant the
                            right
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                <li className="relative h-full">
                  <Link to="/earn">
                    <div className="flex flex-row items-center justify-center h-full">
                      <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                        Earn
                      </span>
                    </div>
                  </Link>
                </li>
                <li className="relative h-full">
                  <Link to="/wallet">
                    <div className="flex flex-row items-center justify-center h-full">
                      <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                        Wallet
                      </span>
                    </div>
                  </Link>
                </li>
                {/*
                   <li
                  className="relative h-full"
                  onMouseOver={walletMouseOver}
                  onMouseOut={walletMouseOut}
                >
                  <div className="flex flex-row items-center justify-center h-full">
                    <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                      Wallet
                    </span>
                  </div>
                  <div
                    className={`${
                      isWalletPopUpMenuVisible ? "" : "hidden"
                    } absolute w-auto h-auto ml-[-1rem] px-3 py-5 top-[48px] z-50 bg-white shadow-lg rounded-xl`}
                  >
                    <Link to="/wallet">
                      <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                        <img className="w-[30px] h-[30px]" src={iconImage} alt="" />
                        <div className="flex flex-col items-start justify-center ml-3">
                          <div className="text-[0.9rem] font-extrabold text-black">Spot Wallet</div>
                          <div className="text-[0.8rem] text-[var(--t-color-gray-600)]">
                            Spot Wallet
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/wallet">
                      <div className="flex flex-row justify-start items-center w-[300px] h-auto px-5 py-2 hover:bg-gray-100">
                        <img className="w-[30px] h-[30px]" src={iconImage} alt="" />
                        <div className="flex flex-col items-start justify-center ml-3">
                          <div className="text-[0.9rem] font-extrabold text-black">
                            Futures Wallet
                          </div>
                          <div className="text-[0.8rem] text-[var(--t-color-gray-600)]">
                            Futures Wallet
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
                  */}

                <li className="relative h-full">
                  <Link to="/trade/history">
                    <div className="flex flex-row items-center justify-center h-full">
                      <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                        Trade History
                      </span>
                    </div>
                  </Link>
                </li>
                <li
                  className="relative h-full"
                  onMouseOver={supportMouseOver}
                  onMouseOut={supportMouseOut}
                >
                  <div className="flex flex-row items-center justify-center h-full">
                    <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                      Support
                    </span>
                  </div>
                  <div
                    className={`${
                      isSupportPopUpMenuVisible ? "" : "hidden"
                    } absolute w-auto h-auto ml-[-1rem] px-3 py-5 top-[48px] z-50 bg-white shadow-lg rounded-xl`}
                  >
                    <Link to="/faq">
                      <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                        <div className="flex flex-col items-start justify-center ml-3">
                          <div className="text-[0.9rem] font-extrabold text-black">Faq</div>
                          <div className="text-[0.8rem] text-[var(--t-color-gray-600)]">
                            FAQ stands for Frequently Asked Questions
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
              </ul>
              <div className="flex flex-row items-center h-full">
                {isLogin && (
                  <section className="flex flex-row items-center h-full">
                    <div
                      className="relative w-[50px] h-full cursor-pointer"
                      onMouseOver={assetMouseOver}
                      onMouseOut={assetMouseOut}
                    >
                      <div className="flex flex-row items-center justify-center w-full h-full">
                        <img src={profileImage} alt="" className="w-[25px] h-[25px] ml-2" />`
                      </div>
                      <div
                        className={`${
                          isAssetPopUpMenuVisible ? "" : "hidden"
                        } absolute w-auto h-auto ml-[-250px] px-3 py-5 top-[48px] z-50 bg-white shadow-lg rounded-xl`}
                      >
                        <Link to="/profile">
                          <section className="relative flex flex-row items-center justify-start px-5 py-3 cursor-pointer">
                            <div className="w-[50px] h-[50px] rounded-full bg-[var(--t-color-point)]">
                              <img src={defaultUserImage} alt="" className="responsive-image" />`
                            </div>
                            <div className="flex flex-col ml-5">
                              <span className="text-[var(--t-color-text)] text-[1rem] font-bold">
                                {userInfo?.name}
                              </span>
                              <span className="text-[var(--t-color-text)] text-[0.8rem]">
                                {userInfo?.email}
                              </span>
                            </div>
                          </section>
                        </Link>
                        <Link to="/wallet">
                          <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100 cursor-pointer">
                            <div className="flex flex-col justify-center items-start w-full p-3 bg-[var(--t-color-point)] rounded-lg">
                              <h2 className="text-[0.9rem] text-white">Total Assets</h2>
                              <h1 className="text-[1.2rem] font-extrabold text-white">
                                {bsc} USDT (BEP-20)
                              </h1>
                              {/* 
                          <h3 className="text-[0.8rem] text-gray-100">= $50.00</h3>
                            */}
                            </div>
                          </div>
                        </Link>
                        <Link to="/deposit">
                          <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                            <div className="flex flex-col items-start justify-center ml-3">
                              <div className="text-[0.9rem] font-extrabold text-black">Deposit</div>
                            </div>
                          </div>
                        </Link>
                        <Link to="/withdraw">
                          <div className="flex flex-row justify-start items-center w-[300px] h-auto px-5 py-2 hover:bg-gray-100">
                            <div className="flex flex-col items-start justify-center ml-3">
                              <div className="text-[0.9rem] font-extrabold text-black">
                                Withdraw
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/*
                            <Link to="/transfer">
                          <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                            <div className="flex flex-col items-start justify-center ml-3">
                              <div className="text-[0.9rem] font-extrabold text-black">
                                Transfer
                              </div>
                            </div>
                          </div>
                        </Link>
                          */}
                        <Link to="/transfer/futures">
                          <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100">
                            <div className="flex flex-col items-start justify-center ml-3">
                              <div className="text-[0.9rem] font-extrabold text-black">
                                Transfer futures
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div
                          className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2 hover:bg-gray-100"
                          onClick={doLogout}
                        >
                          <div className="flex flex-col items-start justify-center ml-3">
                            <div className="text-[0.9rem] font-extrabold text-red-500">Log out</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="relative w-[50px] h-full cursor-pointer"
                      onMouseOver={notiMouseOver}
                      onMouseOut={notiMouseOut}
                    >
                      <div className="relative flex flex-row items-center justify-center w-full h-full">
                        <img
                          src={notiImage}
                          alt=""
                          className="w-[25px] h-[25px] ml-2 object-cover"
                        />
                        `
                      </div>
                      <div
                        className={`${
                          isNotiPopUpMenuVisible ? "" : "hidden"
                        } absolute w-auto h-auto ml-[-250px] px-3 py-5 top-[48px] z-50 bg-white shadow-lg rounded-xl`}
                      >
                        <div className="flex flex-row justify-start items-center w-[300px] h-auto mt-1 px-5 py-2">
                          {alarms.map((alarm: any, index: number) => (
                            <div className="flex flex-col items-start justify-center ml-3">
                              <h1 className="text-[0.9rem] text-black"> {alarm.content}</h1>
                              <h2 className="mt-1 text-[0.7rem] text-[var(--t-color-gray-600)]">
                                {formatDate(alarm.insertDate)}
                              </h2>
                            </div>
                          ))}
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </section>
                )}
                {!isLogin && (
                  <Link to="/login">
                    <span className="mr-5 text-[0.9rem] font-extrabold text-white cursor-pointer">
                      Login/Register
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="fixed left-0 top-0 w-full h-[48px] bg-[var(--t-color-gray-800)] backdrop-blur-xl z-50">
          <div className="relative flex flex-row justify-between w-full h-full pl-5 pr-2">
            <Link to="/">
              <div className="flex flex-row items-center h-full">
                <img src={logoImage} alt="" className="w-[30px] h-[30px]" />
                <h1 className="ml-2 font-extrabold text-[1rem] text-white cursor-pointer">Vibex</h1>
              </div>
            </Link>
            <div className="flex flex-row items-center mr-2" onClick={props.openMenu}>
              <img src={menuImage} alt="" className="w-[30px] h-[30px]" />
            </div>
          </div>
        </div>
      </Mobile>
    </header>
  );
};

export default Header;
