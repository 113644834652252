import React, { useState } from "react";
import Header from "../components/ui/Header";
import { Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import Footer from "../components/ui/Footer";

const Fee = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className="w-full h-full text-white">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      <section className="w-full h-full max-w-[1024px] mx-auto">
        <div className="relative content-top content-padding">
          <h1 className="font-bold text-[1.5rem] text-white text-center">Fees</h1>
        </div>
        {/* Futures Fee Rate Section */}
        <section className="mb-10 mt-5">
          <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center sm:text-left">
            Futures Fee Rate
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse border border-gray-700 text-sm sm:text-base">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Futures</th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Maker</th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Taker</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-900">
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">BTC/USDT</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">0.02%</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">0.03%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        {/* Withdrawal Fee Section */}
        <section>
          <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center sm:text-left">
            Withdrawal Fee
          </h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse border border-gray-700 text-sm sm:text-base">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Coin</th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Withdrawal Fee</th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Min. Withdrawal</th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">
                    Single Withdrawal Limit
                  </th>
                  <th className="px-2 sm:px-4 py-2 border border-gray-700">Daily Limit</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-900">
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">USDT</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">5</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">10</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">1M</td>
                  <td className="px-2 sm:px-4 py-2 border border-gray-700">1M</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <Footer />
      </section>
    </div>
  );
};

export default Fee;
