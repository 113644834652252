import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import greenArrow from "../../assets/images/green_up_arrow_icon.svg";
import redArrow from "../../assets/images/red_down_arrow_icon.svg";
import { DepthData, Message } from "../common/types";

interface OrderBookItem {
  price: number;
  quantity: number;
  total: number;
}

interface OrderBookData {
  bids: OrderBookItem[];
  asks: OrderBookItem[];
}

interface OrderBookProps {
  symbol: string;
  tickData: any;
  closePrice: number;
}

const OrderBook: React.FC<OrderBookProps> = ({ symbol, tickData, closePrice }) => {
  const SOCKET_SERVER_URL = "http://15.164.237.126:5000";
  const [orderBook, setOrderBook] = useState<OrderBookData | null>(null);
  const [maxQuantity, setMaxQuantity] = useState<number>(0);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [precision, setPrecision] = useState<number>(0.1);
  const [saveClosePrice, setSaveClosePrice] = useState<number>(0);
  const [flag, setFlag] = useState<boolean>(false);
  
  const ws = useRef<WebSocket | null>(null);

  const calculateTotalQuantity = (orders: OrderBookItem[]): number => {
    return orders.reduce((total, order) => total + order.quantity, 0);
  };

  const getBuyPercentage = (bids: OrderBookItem[], totalQuantity: number): number => {
    const totalBidQuantity = calculateTotalQuantity(bids);
    return (totalBidQuantity / totalQuantity) * 100;
  };

  const getSellPercentage = (asks: OrderBookItem[], totalQuantity: number): number => {
    const totalAskQuantity = calculateTotalQuantity(asks);
    return (totalAskQuantity / totalQuantity) * 100;
  };

  /*
  useEffect(() => {
    const fetchInitialOrderBook = async () => {
      try {
        const response = await axios.get(
          `https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=10`
        );
        const bids = response.data.bids.map((bid: [string, string]) => ({
          price: parseFloat(bid[0]),
          quantity: parseFloat(bid[1]),
          total: parseFloat(bid[0]) * parseFloat(bid[1]),
        }));
        const asks = response.data.asks.map((ask: [string, string]) => ({
          price: parseFloat(ask[0]),
          quantity: parseFloat(ask[1]),
          total: parseFloat(ask[0]) * parseFloat(ask[1]),
        }));
        const maxQuantity = Math.max(
          ...bids.map((bid: OrderBookItem) => bid.quantity),
          ...asks.map((ask: OrderBookItem) => ask.quantity)
        );

        setOrderBook({ bids, asks });
        setMaxQuantity(maxQuantity);
        const totalBidQuantity = calculateTotalQuantity(bids);
        const totalAskQuantity = calculateTotalQuantity(asks);
        setTotalQuantity(totalBidQuantity + totalAskQuantity);
      } catch (error) {
        console.error("Error fetching initial order book:", error);
      }
    };

    fetchInitialOrderBook();

    // Set up WebSocket
    ws.current = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@depth`);

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const bids = data.b.map((bid: [string, string]) => ({
        price: parseFloat(bid[0]),
        quantity: parseFloat(bid[1]),
        total: parseFloat(bid[0]) * parseFloat(bid[1]),
      }));
      const asks = data.a.map((ask: [string, string]) => ({
        price: parseFloat(ask[0]),
        quantity: parseFloat(ask[1]),
        total: parseFloat(ask[0]) * parseFloat(ask[1]),
      }));
      const maxQuantity = Math.max(
        ...bids.map((bid: OrderBookItem) => bid.quantity),
        ...asks.map((ask: OrderBookItem) => ask.quantity)
      );

      setOrderBook({ bids, asks });
      setMaxQuantity(maxQuantity);
      const totalBidQuantity = calculateTotalQuantity(bids);
      const totalAskQuantity = calculateTotalQuantity(asks);
      setTotalQuantity(totalBidQuantity + totalAskQuantity);
    };

    // Cleanup function
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [symbol]);
  */
  useEffect(() => {
    //ws.current = new WebSocket("ws://3.114.52.135:9999");
    ws.current = new WebSocket("ws://139.99.53.165:9999");
    ws.current.onopen = () => {
      ////console.log("Connected to WebSocket server");
      subscribe(symbol, "depth");
    };
    ws.current.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };
    ws.current.onmessage = (event) => {
      //////console.log('Received message:', event.data);
      
      const message: Message = JSON.parse(event.data);
      if (message.type === "depth") {
        if (message.data && message.data.a && message.data.b) {
          // Reverse the 'asks' array
          const reversedAsks = message.data.a.slice().reverse();
          const reversedBids = message.data.b.slice().reverse();

          const bids = reversedBids.map((bid: [string, string]) => ({
            price: parseFloat(bid[0]),
            quantity: parseFloat(bid[1]),
            total: parseFloat(bid[0]) * parseFloat(bid[1]),
          }));
          const asks = message.data.a.map((ask: [string, string]) => ({
            price: parseFloat(ask[0]),
            quantity: parseFloat(ask[1]),
            total: parseFloat(ask[0]) * parseFloat(ask[1]),
          }));
          const maxQuantity = Math.max(
            ...bids.map((bid: OrderBookItem) => bid.quantity),
            ...asks.map((ask: OrderBookItem) => ask.quantity)
          );

          setOrderBook({ bids, asks });
          setMaxQuantity(maxQuantity);
          const totalBidQuantity = calculateTotalQuantity(bids);
          const totalAskQuantity = calculateTotalQuantity(asks);
          setTotalQuantity(totalBidQuantity + totalAskQuantity);
        }
      }
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [symbol]);

  useEffect(() => {
    if (saveClosePrice !== closePrice) {
      if (closePrice > saveClosePrice) {
        setFlag(true);
      } else {
        setFlag(false);
      }
      setSaveClosePrice(closePrice);
    }
  }, [closePrice]);

  const subscribe = (symbol: string, streamType: string) => {
    ////console.log("subscribe", symbol, streamType);
    if (ws.current && symbol && streamType) {
      ws.current.send(JSON.stringify({ type: "subscribe", symbol, streamType }));
    }
  };

  const unsubscribe = (symbol: string, streamType: string) => {
    if (ws.current && symbol && streamType) {
      ws.current.send(JSON.stringify({ type: "unsubscribe", symbol, streamType }));
    }
  };

  const calculateBarWidth = (quantity: number): string => {
    return `${(quantity / maxQuantity) * 100}%`;
  };

  const getPriceChangeClass = (): string => {
    return flag ? "text-green-500": "text-red-500";;
  };

  const getPriceChangeImage = (): string => {
    return flag ? greenArrow : redArrow;
  };

  if (!orderBook) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative w-full h-full px-3 py-8" style={{ display: "flex", flexDirection: "column", height: "auto" }}>
      <div className="flex flex-row justify-between pr-5">
        <select
          id="precision"
          value={precision}
          onChange={(e) => setPrecision(parseFloat(e.target.value))}
          className="py-2 pl-3 pr-3 mt-1 text-base text-white bg-black border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="0.1">0.1</option>
          <option value="0.01">0.01</option>
          <option value="0.001">0.001</option>
        </select>
      </div>
      <div className="flex flex-row justify-between text-[var(--t-color-desc)]">
        <h2 className="text-[var(--t-color-desc)] text-[0.9rem]">Price</h2>
        <h2 className="text-[0.9rem]">Quantity</h2>
        <h2 className="text-[0.9rem]">Total</h2>
      </div>
      <div className="text-[0.9rem]" style={{ flex: 1, overflow: "hidden", position: "relative" }}>
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            position: "relative",
            height: "100%",
            overflow: "auto",
          }}
        >
          {orderBook.asks.slice(0, 10).reverse().map((ask, index) => (
            <li key={index} className="flex items-center justify-between relative mb-0.5">
              <div
                className="absolute top-0 right-0 h-full duration-500 bg-[var(--t-color-darkred)] transition-width ease"
                style={{ width: calculateBarWidth(ask.quantity) }}
              />
              <span className="relative z-10 text-[var(--t-color-red)]">{ask.price.toFixed(2)}</span>
              <span className="absolute z-10 left-[50%] text-white">{ask.quantity.toFixed(2)}</span>
              <span className="relative z-10 text-white">{ask.total.toFixed(2)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row items-center w-full py-1 font-semibold text-white">
        <h2 className={`text-xl ${getPriceChangeClass()}`}>{tickData == null ? "" : Number(tickData?.close).toFixed(2)}</h2>
        <div className="flex items-center justify-center h-full">
          <img className="w-[15px] h-[15px]" src={getPriceChangeImage()} alt="" />
        </div>
      </div>
      <div className="text-[0.9rem]" style={{ flex: 1, overflow: "hidden", position: "relative" }}>
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            position: "relative",
            height: "100%",
            overflow: "auto",
          }}
        >
          {orderBook.bids.slice(0, 10).map((bid, index) => (
            <li key={index} className="flex items-center justify-between relative mb-0.5">
              <div
                className="absolute top-0 right-0 h-full duration-500 bg-[var(--t-color-darkgreen)] transition-width ease"
                style={{ width: calculateBarWidth(bid.quantity) }}
              />
              <span className="relative z-10 text-[var(--t-color-green)]">{bid.price.toFixed(2)}</span>
              <span className="absolute z-10 left-[50%] text-white">{bid.quantity.toFixed(2)}</span>
              <span className="relative z-10 text-white">{bid.total.toFixed(2)}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col mt-2 space-y-2">
        <div className="relative w-full h-1 px-1">
          <span
            className="absolute top-0 left-0 h-full bg-[var(--t-color-green)]"
            style={{ width: `${getBuyPercentage(orderBook?.bids, totalQuantity)}%` }}
          ></span>
          <span
            className="absolute top-0 right-0 h-full bg-[var(--t-color-red)]"
            style={{ width: `${getSellPercentage(orderBook?.asks, totalQuantity)}%` }}
          ></span>
        </div>
        <div className="flex justify-between text-sm">
          <span className="text-[var(--t-color-green)]">
            Buy: {getBuyPercentage(orderBook?.bids, totalQuantity).toFixed(2)}%
          </span>
          <span className="text-[var(--t-color-red)]">
            Sell: {getSellPercentage(orderBook?.asks, totalQuantity).toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
