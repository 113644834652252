import React, { useState, useCallback, useEffect } from "react";
import "../../assets/css/Manager.css";
import HeaderManager from "../../components/ui/HeaderManager";
import axios from "axios";
import { formatDate, formatNumberWithCommas } from "../../components/common/Utils";
import { Link, useNavigate } from "react-router-dom";
import ManagerPartnerList from "../../components/ui/ManagerPartnerList";
import ManagerPartnerInfo from "../../components/ui/ManagerPartnerInfo";
import AlertPopUp from "../../components/ui/AlertPopUp";

const SettlementHistory = () => {
  const navigate = useNavigate();

  const hostUrl = process.env.REACT_APP_HOST_URL;

  const [isSelectedApply, setIsSelectedApply] = useState(true);
  const [data, setData] = useState<any>(null);
  const [applyDatas, setApplyDatas] = useState<any>([]);
  const [settlementDatas, setSettlementDatas] = useState<any>([]);
  const [settlement, setSettlement] = useState<number>(100);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");

  useEffect(() => {
    const level = localStorage.getItem("level");
    let levelNumber: number = 1;
    if (level != null) {
      levelNumber = parseInt(level);
    }
    if (levelNumber < 2) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    requestManagerSettlementLookup();
    requestWithdrawSettlementLookup();
  }, []);

  function changeTab(selectedTab: boolean) {
    setIsSelectedApply(selectedTab);
    if (selectedTab) {
      requestWithdrawSettlementLookup();
    } else {
      requestSettlementLookup();
    }
  }

  function doRequestWithdrawSettlement() {
    /*
    if (withdrawSettlement === 0 || withdrawSettlement <= 0) {
      showAlertPopup("Please enter the amount to withdraw.");
      return;
    }
      */

    requestWithdrawSettlement();
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  /**
   * api
   */
  const requestManagerSettlementLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/settlement";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.data.code === 0) {
        setData(response.data.data);
        setSettlement(response.data.data.settlement - response.data.data.withdrawSettlement);
      }
    } catch (error) {}
  };

  const requestWithdrawSettlementLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/withdrawSettlementLookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        setApplyDatas(response.data.data);
      }
    } catch (error) {}
  };

  const requestSettlementLookup = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/manager/settlementLookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        setSettlementDatas(response.data.data);
      }
    } catch (error) {}
  };

  const requestWithdrawSettlement = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        hostUrl + "/manager/registerWithdrawSettlement",
        {
          settlement,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        }
      );
      ////console.log(response);
      if (response.data.code === 0) {
        showAlertPopup("Withdrawal request has been completed.");
        requestManagerSettlementLookup();
      } else {
        showAlertPopup(response.data.message);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full h-full">
      <section className="w-full h-full manager">
        <HeaderManager />
        <section className="main">
          <h1 data-lang="title_settlement">Settlement History</h1>
          <h4 data-lang="title_settlement_desc" className="mrk">
            *Estimated settlement amount on the day is updated every 5 minutes.
          </h4>

          <section className="refer">
            <div className="summary">
              <div>
                <h2>
                  <span>User Deposit</span>
                  {data && formatNumberWithCommas(data.belowUserCompleteDeposits)}
                </h2>
              </div>
              <div>
                <h2>
                  <span>User Withdraw</span>
                  {data && formatNumberWithCommas(data.belowUserCompleteWithdraws)}
                </h2>
              </div>
              <div>
                <h2>
                  <span>User Balance</span>
                  {data && formatNumberWithCommas(data.belowUserBalance)}
                </h2>
              </div>
              <div>
                <h2>
                  <span>Received</span>
                  {data && formatNumberWithCommas(data.receive)}
                </h2>
              </div>
              <div>
                <h2>
                  <span>Today Settlement</span>
                  {data && formatNumberWithCommas(data.todaySettlement)}
                </h2>
              </div>
            </div>
          </section>

          <section className="withdraw">
            <div className="inner">
              <h2>
                {data && formatNumberWithCommas(data.settlement - data.withdrawSettlement)}
              </h2>
              <div className="price">
                <input
                  type="text"
                  id="price"
                  value={
                    data && formatNumberWithCommas(data.settlement - data.withdrawSettlement)
                  }
                  disabled
                />
              </div>
              <button data-lang="btn_withdraw_apply" onClick={doRequestWithdrawSettlement}>
                Apply
              </button>
            </div>
            <h2 data-lang="subtitle_withdraw_history">Apply History</h2>
            <section className="tab">
              <button className={isSelectedApply ? "selected" : ""} onClick={() => changeTab(true)}>
                Apply
              </button>
              <button
                className={isSelectedApply ? "" : "selected"}
                onClick={() => changeTab(false)}
              >
                Settlement
              </button>
            </section>
            {isSelectedApply && (
              <table>
                <thead>
                  <tr>
                    <th data-lang="table_date">Date</th>
                    <th data-lang="table_state">State</th>
                    <th data-lang="table_withdraw_amount">Withdrawal Amounts</th>
                  </tr>
                </thead>
                <tbody>
                  {applyDatas.map((apply: any, index: number) => (
                    <tr key={index} className="text-[0.8rem] text-white">
                      <td>{formatDate(apply.insertDate)}</td>
                      <td>
                        {apply.status === 1 && "진행중"}
                        {apply.status === 2 && "완료"}
                        {apply.status === 3 && "취소"}
                        {apply.status === 4 && "거절"}
                      </td>
                      <td>{apply.settlement}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!isSelectedApply && (
              <table>
                <thead>
                  <tr>
                    <th data-lang="table_date">Date</th>
                    <th data-lang="table_state">Rate</th>
                    <th data-lang="table_withdraw_amount">Settlement Amounts</th>
                  </tr>
                </thead>
                <tbody>
                  {settlementDatas.map((settlement: any, index: number) => (
                    <tr key={index} className="text-[0.8rem] text-white">
                      <td>{settlement.day}</td>
                      <td>{settlement.nowFee}</td>
                      <td>{settlement.settlement}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>
        </section>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default SettlementHistory;
