import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Header from "../components/ui/Header";
import { Desktop, Mobile } from "../hooks/mediaQuery";
import Aside from "../components/ui/Aside";
import News from "../components/ui/News";
import {
  ColorType,
  DeepPartial,
  IPriceLine,
  ISeriesApi,
  LastPriceAnimationMode,
  LayoutOptions,
  LineData,
  LineStyle,
  LineWidth,
  PriceLineOptions,
  Time,
  UTCTimestamp,
  createChart,
} from "lightweight-charts";
import OrderBook from "../components/ui/OrderBook";
import PriceInfo from "../components/ui/PriceInfo";
import TradeOption from "../components/ui/TradeOption";
import TradeOptionAccount from "../components/ui/TradeOptionAccount";
import Assets from "../components/ui/Assets";
import ToastPopUp from "../components/ui/ToastPopUp";
import AlertPopUp from "../components/ui/AlertPopUp";

interface MarkPriceUpdate {
  e: string; // Event type
  E: number; // Event time
  s: string; // Symbol
  p: string; // Mark price
  i: number; // Index price
  P: number; // Estimated Settle Price, only useful in the last hour before the settlement starts
  r: number; // Funding rate
  T: number; // Next funding time
}

interface Message {
  type: string;
  symbol: string;
  data: any;
  optionPositions: any;
}

const Options = () => {
  const hostUrl = process.env.REACT_APP_HOST_URL;
  //const SOCKET_SERVER_URL: string = "ws://3.114.52.135:7777";
  const SOCKET_SERVER_URL: string = "ws://139.99.53.165:7777";
  const SOCKET_ORDER_SERVER_URL: string = "ws://3.114.52.135:9998";

  const chartHeight = 670;

  var areaSeries: any = null;
  //var priceLines: IPriceLine[] = [];

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [userNo, setUserNo] = useState<number>(0);
  const [priceLines, setPriceLines] = useState<IPriceLine[]>([]);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const lineSeriesRef = useRef<ISeriesApi<"Line">>(null!);
  const areaSeriesRef = useRef<ISeriesApi<"Area">>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [data, setDatas] = useState([]);
  const [optionPositions, setOptionPositions] = useState([]);
  const [optionHistories, setOptionHistories] = useState([]);
  const [tickData, setTickData] = useState<any>(null);
  const [closePrice, setClosePrice] = useState<number>(0);
  const [symbol, setSymbol] = useState<string>("BTCUSDT");
  const [amount, setAmount] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState(60); // 초기 타이머는 60초 (1분)
  const [serverTime, setServerTime] = useState(null);
  const [isRequestOptionOrderTop, setIsRequestOptionOrderTop] = useState(false);
  //
  const [asset, setAsset] = useState<any>(null);
  const [position, setPosition] = useState<any>(null);
  // socket
  const ws = useRef<WebSocket | null>(null);
  const orderWs = useRef<WebSocket | null>(null);
  const positionWs = useRef<WebSocket | null>(null);
  // AlertPopUp
  const [isOpenAlertPopUp, setIsOpenAlertPopUp] = useState(false);
  const [alertPopUpDesc, setAlertPopUpDesc] = useState<string>("");
  // ToastPopUp
  const [toastPopUpHidden, setToastPopUpHidden] = useState<string>("hidden");
  const [toastPopUpDesc, setToastPopUpDesc] = useState<string>("");

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const layoutOptions: DeepPartial<LayoutOptions> = {
      background: {
        type: ColorType.Solid,
        color: "#000",
      },
      textColor: "#fff",
    };

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: layoutOptions,
      timeScale: {
        rightOffset: 12,
        borderColor: "#6666ff",
        timeVisible: true,
        secondsVisible: true,
        tickMarkFormatter: (time: UTCTimestamp) => {
          const date = new Date(time * 1000);
          date.setHours(date.getHours());
          //date.setHours(date.getHours() + 9); // Adjust for UTC+9 timezone
          return `${date.getHours().toString().padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
        },
      },
      crosshair: {
        horzLine: {
          visible: true,
          labelVisible: true,
        },
        vertLine: {
          labelVisible: true,
        },
      },
      grid: {
        vertLines: {
          color: "rgba(102,102,255,0.5)",
          style: 1, // 점선 스타일
        },
        horzLines: {
          color: "rgba(102,102,255,0.5)",
          style: 1,
        },
      },
    });

    /*
    const lineSeries = chart.addLineSeries({
      color: "#75ff8c",
      lineWidth: 1,
      lastPriceAnimation: LastPriceAnimationMode.OnDataUpdate, // 마지막 가격 애니메이션
    });
    */

    areaSeries = chart.addAreaSeries({
      topColor: "rgba(102,102,255, 0.28)",
      bottomColor: "rgba(102,102,255, 0.05)",
      lineColor: "#6666ff",
      lineWidth: 1,
      lastPriceAnimation: LastPriceAnimationMode.OnDataUpdate,
    });

    // Function to format current time as string
    const getCurrentDateTimeString = () => {
      const now = new Date();
      const year = now.getFullYear().toString();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    // Example data for the vertical line
    const now = getCurrentDateTimeString(); // Current time as string
    const verticalLineData = [
      { time: "2024-05-21", value: 100 }, // Start point
    ];

    // Create a line series for the vertical line
    const verticalLineSeries = chart.addLineSeries({
      color: "red", // Adjust color as needed
      lineWidth: 1, // Adjust line width as needed
    });
    //verticalLineSeries.setData(verticalLineData);

    // 예시 데이터
    const data: LineData[] = [];

    //lineSeries.setData(data);
    //areaSeries.setData(data);

    // 타입 단언을 사용하여 readonly 속성 우회
    //(lineSeriesRef as React.MutableRefObject<ISeriesApi<"Line"> | null>).current = lineSeries;
    (areaSeriesRef as React.MutableRefObject<ISeriesApi<"Area"> | null>).current = areaSeries;

    const resizeObserver = new ResizeObserver(() => {
      chart.applyOptions({
        width: chartContainerRef.current?.clientWidth,
        height: chartContainerRef.current?.clientHeight,
      });
    });
    resizeObserver.observe(chartContainerRef.current);

    chart.applyOptions({
      rightPriceScale: {
        visible: true,
        autoScale: true,
      },
    });
    chart.timeScale().fitContent();

    requestUserInfo();
    getFirstData();

    return () => {
      resizeObserver.disconnect();
      chart.remove();
    };
  }, []);

  useEffect(() => {
    ws.current = new WebSocket(SOCKET_SERVER_URL);
    ws.current.onopen = () => {
      ////console.log("Connected to WebSocket server");
      //subscribe(symbol, "ticker");
    };
    ws.current.onclose = () => {
      //////console.log("Disconnected from WebSocket server");
    };
    ws.current.onmessage = (event) => {
      ////console.log('Received message:', event.data);
      const data: any = JSON.parse(event.data);
      ////console.log(data);
      setTickData(data);
      if (areaSeriesRef.current) {
        const close: number = Number(data.close);
        setClosePrice(close);
        areaSeriesRef.current.update({
          time: (data.closeTime / 1000) as UTCTimestamp,
          value: close,
        });
      }
      /*
      if (message.type === "ticker") {
        setTickData(message.data);
        //////console.log('ticker', message.data);
        if (areaSeriesRef.current) {
          const close: number = Number(message.data.close);
          setClosePrice(close);
          areaSeriesRef.current.update({
            time: (message.data.closeTime / 1000) as UTCTimestamp,
            value: close,
          });
        }
      }
        */
      //setData(`${message.symbol} ${message.type}:\n${JSON.stringify(message.data, null, 2)}`);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    /*
    if (isAuthenticated) {
      orderWs.current = new WebSocket(SOCKET_ORDER_SERVER_URL);
      orderWs.current.onopen = () => {
        //////console.log("Connected to WebSocket server");
        orderWs.current?.send(
          JSON.stringify({
            type: "optionPositionsSubscribe",
            userNo,
          })
        );
      };
      orderWs.current.onclose = () => {
        //////console.log("Disconnected from WebSocket server");
      };
      orderWs.current.onmessage = (event) => {
        const message: Message = JSON.parse(event.data);
        if (message.type === "optionPositions") {
          //////console.log("optionPositions", message.optionPositions);
          setOptionPositions(message.optionPositions);
          //setData(prevData => [...message.orders!, ...prevData]);
        }
      };
  
      return () => {
        if (orderWs.current) {
          orderWs.current.send(
            JSON.stringify({
              type: "optionPositionsUnsubscribe",
              userNo,
            })
          );
          orderWs.current.close();
        }
      };
    }
    */
  }, []);

  const fetchServerTime = async () => {
    try {
      const response = await axios.get(hostUrl + "/server/serverTime");
      const currentTimestamp = response.data.timestamp;
      const now = Math.floor(Date.now() / 1000);
      const secondsLeft = 60 - (now % 60); // 현재 시간의 남은 초 계산
      setServerTime(currentTimestamp);
      setTimeLeft(secondsLeft);
    } catch (error) {
      //console.error("Error fetching server time:", error);
    }
  };

  useEffect(() => {
    fetchServerTime();
    requestPositions();
  }, []);

  useEffect(() => {
    if (serverTime === null) return;
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          clearInterval(timer);
          fetchServerTime();
          removeAllPriceLines();
          requestOptionWaitOrder();
          requestOptionOrderHistory();
          return 60;
        }
        if (prevTimeLeft == 55) {
          requestUserBalance();
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [serverTime]);

  const subscribe = (symbol: string, streamType: string) => {
    //////console.log("subscribe", symbol, streamType);
    if (ws.current && symbol && streamType) {
      ws.current.send(JSON.stringify({ type: "subscribe", symbol, streamType }));
    }
  };

  const unsubscribe = (symbol: string, streamType: string) => {
    if (ws.current && symbol && streamType) {
      ws.current.send(JSON.stringify({ type: "unsubscribe", symbol, streamType }));
    }
  };

  useEffect(() => {
    let interval: any = null;

    const requestOptionOrderTop = async (): Promise<void> => {
      var accessToken = localStorage.getItem("accessToken");
      var requestUrl = hostUrl + "/optionOrder/top";
      try {
        const response = await axios.get(requestUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.data.code === 0) {
          if (response.data.data.length > 0) {
            setOptionPositions(response.data.data);
          }
        }
      } catch (error) {}
    };

    if (isRequestOptionOrderTop) {
      requestOptionOrderTop(); // 즉시 호출
      interval = setInterval(() => {
        requestOptionOrderTop();
      }, 5000); // 1초마다 fetchData 호출
    } else if (interval) {
      clearInterval(interval); // 멈출 때 인터벌 정리
    }

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
  }, [isRequestOptionOrderTop]);

  function goLogin() {
    window.location.href = "/login";
  }

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function createPriceLine(price: number, title: string, color: string = "red"): IPriceLine {
    const line: PriceLineOptions = {
      price: price,
      color: color,
      lineWidth: 1 as LineWidth, // Cast to LineWidth
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title: title,
      lineVisible: true,
      axisLabelColor: "",
      axisLabelTextColor: "",
    };
    /*
    const priceLine = areaSeries.createPriceLine(line);
    areaSeries.applyPriceLine(priceLine);
    */
    var priceLine = null;
    if (areaSeriesRef.current) {
      priceLine = areaSeriesRef.current.createPriceLine(line);
    }
    return priceLine!;
  }

  function removeAllPriceLines() {
    priceLines.forEach((priceLine) => {
      // ////console.log("removePriceLine", priceLine);
      areaSeriesRef.current!.removePriceLine(priceLine);
    });
    priceLines.length = 0;
  }

  function changeAmount(value: number) {
    //////console.log("changeAmount", value);
    setAmount(value);
  }

  function doLong() {
    if (!isAuthenticated) {
      goLogin();
      return;
    }
    if (amount <= 0) {
      showAlertPopup("Please enter the amount");
      return;
    }
    requestOptionOrder(1);
  }

  function doShort() {
    if (!isAuthenticated) {
      goLogin();
      return;
    }
    if (amount <= 0) {
      showAlertPopup("Please enter the amount");
      return;
    }
    requestOptionOrder(2);
  }

  const showAlertPopup = (popupDesc: string) => {
    setAlertPopUpDesc(popupDesc);
    setIsOpenAlertPopUp(true);
  };

  const hideAlertPopup = () => {
    setIsOpenAlertPopUp(false);
    setAlertPopUpDesc("");
  };

  const showToastPopup = (popupDesc: string) => {
    setToastPopUpHidden("");
    setToastPopUpDesc(popupDesc);
    hideToastPopup();
  };

  const hideToastPopup = () => {
    setTimeout(function () {
      setToastPopUpHidden("hidden");
      setToastPopUpDesc("");
    }, 2000);
  };

  const requestUserInfo = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/info";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setIsAuthenticated(true);
        setUserInfo(response.data.data);
        requestUserBalance();
        requestOptionWaitOrder();
        setIsRequestOptionOrderTop(true);
        requestOptionOrderHistory();
      } else {
        setIsAuthenticated(false);
        setUserInfo(null);
      }
    } catch (error) {}
  };

  async function getFirstData() {
    const response = await axios.get(
      `https://fapi.binance.com/fapi/v1/klines?symbol=${symbol}&interval=1m`
    );
    const datas = response.data.map((data: any) => ({
      time: parseFloat(data[0]) / 1000,
      value: parseFloat(data[4]),
    }));
    if (areaSeriesRef.current) {
      areaSeriesRef.current.setData(datas);
    }
  }

  const requestPositions = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/postion/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(response);
      if (response.data.code == 0) {
        if (response.data.data.length > 0) {
          setPosition(response.data.data[0]);
        }
      }
    } catch (error) {}
  };

  const requestOptionWaitOrder = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/optionOrder/waitLookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code === 0) {
        removeAllPriceLines();
        if (response.data.data.length > 0) {
          for (var i = 0; i < response.data.data.length; i++) {
            var order = response.data.data[i];
            if (order.betType === 1) {
              const priceLine: IPriceLine = createPriceLine(order.openPrice, "LONG", "#FC3044");
              priceLines.push(priceLine);
            } else if (order.betType === 2) {
              const priceLine: IPriceLine = createPriceLine(order.openPrice, "SHORT", "#32CC86");
              priceLines.push(priceLine);
            }
          }
        }
      }
    } catch (error) {}
  };

  const requestOptionOrderHistory = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/optionOrder/lookup";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      ////console.log(response);
      if (response.data.code === 0) {
        if (response.data.data.list.length > 0) {
          setOptionHistories(response.data.data.list);
        }
      }
    } catch (error) {}
  };

  const requestOptionOrder = async (betType: number): Promise<void> => {
    const accessToken = localStorage.getItem("accessToken");
    var requestSymbol: number = 1;
    try {
      const response = await axios.post(
        hostUrl + "/optionOrder/register",
        {
          symbol: requestSymbol,
          betType: betType,
          volume: amount,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.code === 0) {
        requestUserBalance();
        requestOptionWaitOrder();
        showAlertPopup("Order successfully completed.");
      } else {
        showAlertPopup(response.data.message);
      }
    } catch (error) {}
  };

  const requestUserBalance = async (): Promise<void> => {
    var accessToken = localStorage.getItem("accessToken");
    var requestUrl = hostUrl + "/user/balance";
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.code == 0) {
        setAsset(response.data.data);
      }
    } catch (error) {}
  };

  return (
    <div className="relative w-full h-full">
      <Header openMenu={openMenu} closeMenu={closeMenu} />
      <Mobile>
        <Aside isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
      </Mobile>
      {/*
         <section className="w-full h-full bg-[var(--t-color-gray-800)]">
        */}
      <section className="w-full h-full bg-black">
        <News isNewsShow={false} />
        {/* favorite */}
        {/*isDesktop && (
          <div className="flex flex-row items-center justify-start px-5 py-2 bg-black">
            <span className="text-[0.8rem] font-extrabold text-white">Favorites</span>
            <span className="ml-3 text-[0.8rem] font-extrabold text-[var(--t-color-gray-400)]">
              BTCUSDT
            </span>
            <span className="ml-3 text-[0.8rem] font-extrabold text-[var(--t-color-gray-400)]">
              ETCUSDT
            </span>
          </div>
        )*/}
        {/* info */}
        <div className="flex flex-row items-center justify-start w-full h-auto mt-1">
          <div
            className={`flex flex-col justify-start items-start w-full ${
              isDesktop ? "h-[60px]" : "h-suto"
            }`}
          >
            <PriceInfo tickData={tickData} />
            <div className="flex flex-row items-start justify-start w-full h-auto mt-5">
              <div
                className={`flex flex-col items-start justify-start ${
                  isDesktop ? "w-4/5" : "w-full mt-2"
                } h-auto`}
              >
                <div className="flex flex-row items-center justify-start w-full h-full pl-1">
                  {/* chart */}
                  <div
                    className={`flex flex-col justify-start items-start ${
                      isDesktop ? "w-[70%]" : "w-full"
                    } h-full px-1 bg-black`}
                  >
                    <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
                      <div className="flex flex-row items-center justify-between w-full h-full border-b-2 border-white">
                        <span className="text-[0.9rem] font-normal text-white">Chart</span>
                        <div className="flex flex-row items-center justify-center">
                          <span className="text-[0.9rem] font-normal text-white">
                            Expiration Time :
                            <span className="ml-1 mr-2 px-3 py-1 border border-[var(--t-color-point)] font-bold text-[var(--t-color-point)] rounded-xl">
                              {timeLeft}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={`w-full ${isDesktop ? "h-[670px]" : "h-[400px]"}`}>
                      <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }} />
                    </div>
                  </div>
                  {isDesktop && (
                    <div className="flex flex-col justify-start items-start w-[29%] h-full ml-1 bg-black">
                      <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
                        <div className="flex flex-row items-center justify-center w-auto h-full border-b-2 border-white">
                          <span className="text-[0.9rem] font-normal text-white">Order book</span>
                        </div>
                      </div>
                      <div className="w-full h-full">
                        <OrderBook symbol="BTCUSDT" tickData={tickData} closePrice={closePrice} />
                      </div>
                    </div>
                  )}
                </div>
                {isDesktop && (
                  <div className="flex flex-col items-start justify-start w-full h-full px-1 bg-black">
                    <TradeOptionAccount
                      optionPositions={optionPositions}
                      optionHistories={optionHistories}
                    />
                  </div>
                )}
              </div>
              {isDesktop && (
                <div className="relative w-1/5 h-full ml-[-13px] px-3 bg-black">
                  <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
                    <div className="flex flex-row items-center justify-center w-auto h-full border-b-2 border-white">
                      <span className="text-[0.9rem] font-normal text-white">Trade</span>
                    </div>
                  </div>
                  <TradeOption
                    changeAmount={changeAmount}
                    doLong={doLong}
                    doShort={doShort}
                    asset={asset}
                    position={position}
                    timeLeft={timeLeft}
                  />
                  <div className="w-full h-[auto] h-min-[500px] px-3 bg-black">
                    <Assets asset={asset} position={position} isOption={true} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile && (
          <div className="relative w-full h-full px-3 bg-black">
            <div className="flex flex-row justify-start items-start w-full h-[40px] border-b border-gray-700">
              <div className="flex flex-row items-center justify-center w-auto h-full border-b-2 border-white">
                <span className="text-[0.9rem] font-normal text-white">Trade</span>
              </div>
            </div>
            <TradeOption
              changeAmount={changeAmount}
              doLong={doLong}
              doShort={doShort}
              asset={asset}
              position={position}
              timeLeft={timeLeft}
            />
            <div className="flex flex-col items-start justify-start w-full h-auto px-1 pb-10 bg-black">
              <TradeOptionAccount
                optionPositions={optionPositions}
                optionHistories={optionHistories}
              />
            </div>
            <div className="w-full h-auto px-3 pb-10 bg-black">
              <Assets asset={asset} position={position} isOption={true} />
            </div>
          </div>
        )}
        <div className="flex flex-row justify-center w-full h-auto">
          <ToastPopUp hidden={toastPopUpHidden} desc={toastPopUpDesc} />
        </div>
        {isOpenAlertPopUp && <AlertPopUp message={alertPopUpDesc} onClose={hideAlertPopup} />}
      </section>
    </div>
  );
};

export default Options;
